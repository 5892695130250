export interface Resource {
  lang: string;
  type: string;
  files?: ResourceFile[];
}

export interface ResourceFile {
  title: string;
  zip?: boolean;
  pdf?: boolean;
  link?: string;
  duration?: string;
  media?: MediaInfo[];
}

export interface MediaInfo {
  r?: string;
  size: string;
  link: string;
}

export const RESOURCES: Resource[] = [
  {
    lang: 'English',
    type: 'Documents',
    files: [
      {
        title: 'Session Materials',
        zip: true,
        link: 'https://isha-apac.b-cdn.net/yogaveera-training/Presentation%20Materials/en/YV%20Session%20Materials%20English.zip'
      }
    ]
  },
  {
    lang: 'English',
    type: 'Video',
    files: [
      {
        title: 'Intro to Sadhguru & Upa Yoga',
        duration: '8mins',
        media: [
          { r: '1280x720', size: '128MB', link: 'https://isha-apac.b-cdn.net/yogaveera-training/Presentation%20Materials/en/0.%20Intro%20to%20Sadhguru%20%26%20Upa%20Yoga.mp4' }
        ]
      },
      {
        title: 'Yoga for Beginners',
        duration: '48mins',
        media: [
          { r: '1280x720', size: '783MB', link: 'https://isha-apac.b-cdn.net/yogaveera-training/Presentation%20Materials/en/1.%20Yoga%20for%20Beginners_YV.mp4' }
        ]
      },
      {
        title: 'Meditation for Beginners',
        duration: '39mins',
        media: [
          { r: '1280x720', size: '588MB', link: 'https://isha-apac.b-cdn.net/yogaveera-training/Presentation%20Materials/en/2.%20Meditation%20for%20Beginners_YV.mp4' }
        ]
      },
      {
        title: 'Intro to Inner Engineering (G20, 7min) + Isha Kriya',
        duration: '32mins',
        media: [
          { r: '1280x720', size: '492MB', link: 'https://isha-apac.b-cdn.net/yogaveera-training/Presentation%20Materials/en/3a.%20IE%20intro%20(G20%2C%207min)%20%2B%20IK_YV.mp4' }
        ]
      },
      {
        title: 'Intro to Inner Engineering (G20, 15min) + Isha Kriya',
        duration: '40mins',
        media: [
          { r: '1280x720', size: '604MB', link: 'https://isha-apac.b-cdn.net/yogaveera-training/Presentation%20Materials/en/3b.%20IE%20intro%20(G20%2C%2015mins)%20%2B%20IK_YV.mp4' }
        ]
      },
      {
        title: 'Corrections - Yoga for Beginners',
        duration: '21mins',
        media: [
          { r: '1280x720', size: '784MB', link: 'https://isha-apac.b-cdn.net/yogaveera-training/Presentation%20Materials/en/4.%20Corrections_Yoga%20for%20Beginners.mp4' }
        ]
      },
      {
        title: 'Intro to Inner Engineering - Short Video',
        duration: '5mins',
        media: [
          { r: '960x540', size: '52MB', link: 'https://isha-apac.b-cdn.net/yogaveera-training/Presentation%20Materials/en/IE%20Intro_short.mp4' }
        ]
      },
      {
        title: 'Yoga Padi Audio',
        duration: '30mins',
        media: [
          { size: '56MB', link: 'https://isha-apac.b-cdn.net/yogaveera-training/Presentation%20Materials/Yoga%20Padi%20full%20audio.mp3' }
        ]
      }
    ]
  },
  {
    lang: 'Chinese',
    type: 'Video',
    files: [
      {
        title: 'Session Video',
        duration: '90mins',
        media: [
          { r: '1920x1080', size: '1.48GB', link: 'https://files.ishayoga.sg/iuy/zh/90mins-1920x1080.mp4' }
        ]
      },
    ]
  },
  {
    lang: 'Korean',
    type: 'Video',
    files: [
      {
        title: 'Session Video',
        duration: '90mins',
        media: [
          { r: '1280x720', size: '450MB', link: 'https://files.ishayoga.sg/iuy/ko/90mins-1280x720.mp4' }
        ]
      },
    ]
  },
  {
    lang: 'Hindi',
    type: 'Video',
    files: [
      {
        title: 'Session Video',
        duration: '90mins',
        media: [
          { r: '1024x576', size: '685MB', link: 'https://files.ishayoga.sg/iuy/hi/90mins-1024x576.mp4' }
        ]
      },
    ]
  },
  {
    lang: 'Tamil',
    type: 'Video',
    files: [
      {
        title: 'Session Video',
        duration: '90mins',
        media: [
          { r: '1280x720', size: '717MB', link: 'https://files.ishayoga.sg/iuy/ta/90mins-1280x720.mp4' }
        ]
      },
      {
        title: 'Session Video',
        duration: '45mins',
        media: [
          { r: '1280x720', size: '398MB', link: 'https://files.ishayoga.sg/iuy/ta/45mins-1280x720.mp4' }
        ]
      },
    ]
  },
  {
    lang: 'Telugu',
    type: 'Video',
    files: [
      {
        title: 'Session Video',
        duration: '90mins',
        media: [
          { r: '1024x576', size: '669MB', link: 'https://files.ishayoga.sg/iuy/te/90mins-1024x576.mp4' }
        ]
      },
      {
        title: 'Session Video',
        duration: '45mins',
        media: [
          { r: '1280x720', size: '362MB', link: 'https://files.ishayoga.sg/iuy/te/45mins-1024x576.mp4' }
        ]
      },
    ]
  }
];
