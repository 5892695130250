import {Component} from '@angular/core';
import {HOSTED_URL, PROMO_MATERIALS} from "./promo-materials";

@Component({
  selector: 'app-promote',
  templateUrl: './promote.component.html',
  styleUrls: ['./promote.component.scss']
})
export class PromoteComponent {
  promo = PROMO_MATERIALS;
  server = HOSTED_URL;
}
