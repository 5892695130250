<div class="container" fxLayout fxLayoutGap="5px" fxLayoutAlign="center">
  <div fxFlex="20%" fxFlex.xs="5px"></div>
  <div fxFlex fxLayout="column">
    <div class="heading">
      <h2>Manage your Sessions</h2>
      <button mat-raised-button color="green" (click)="createSession()" >
        Create
      </button>
    </div>
    <div class="grid-parent">
      <ag-grid-angular
        style="width: 100%; height: 100%"
        class="ag-theme-alpine"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="sessions"
        [rowSelection]="'multiple'"
        [animateRows]="true"
        (gridReady)="onGridReady($event)"
      ></ag-grid-angular>
    </div>
  </div>
  <div fxFlex="20%" fxFlex.xs="5px"></div>
</div>


