<div class="container" fxLayout fxLayoutGap="10px" fxLayoutAlign="center">
  <div fxFlex="20%" fxFlex.lt-sm="5px"></div>
  <div fxFlex fxLayout="column" fxLayoutGap="20px">
    <div></div>
    <div *ngIf="completed">
      <app-post-training-iuy [training]="trainings[0]"></app-post-training-iuy>
    </div>
    <div>
      <div class="heading">
        <h2>Trainings</h2>
      </div>
      <div class="training" fxLayout="column" fxLayoutGap="20px">
        <div *ngFor="let t of trainings" fxFlex fxLayoutGap="10px">
          <mat-card fxLayoutGap="10px" fxLayout="row" *ngIf="!t.hide">
            <mat-card-content fxLayoutGap="10px">
              <div fxFlex="30%">
                <img src='assets/{{t.img}}/thumbnail.jpg' alt=""/>
              </div>
              <div fxFlex fxLayout="column" fxLayoutGap="2px">
                <div><span class="title">{{t.displayName}}</span></div>
                <div><span class="label recorded">{{t.type}}</span></div>
                <div><fa-icon [icon]="faClock"></fa-icon> Duration {{t.durationMs / 1000 / 60}} mins</div>
                <div *ngIf="t.hideAt?.isValid()"><fa-icon [icon]="faHourglass"></fa-icon> Available until {{t.hideAt?.toISOString() | date:"dd MMM YYYY h:mm a (Z)"}}</div>
                <div><hr></div>
                <div fxFlex fxLayoutGap="10px" fxLayout="row">
                  <button mat-raised-button color="brown-orange" [routerLink]="[t.attendLink]" *ngIf="!t.participant?.registeredAt">
                    ENROL NOW
                  </button>
                  <button mat-raised-button color="green" [routerLink]="[t.attendLink]" *ngIf="t.participant?.registeredAt && !t.participant?.currentPositionMs">
                    START
                  </button>
                  <button mat-raised-button color="blue" [routerLink]="[t.attendLink]" *ngIf="t.participant?.registeredAt && t.participant?.currentPositionMs">
                    RESUME
                  </button>
                  <button mat-raised-button color="green" (click)="openModal(t)" *ngIf="!this.completed">
                    <div fxLayoutAlign="center center" fxLayoutGap="5px">
                      <span>COMPLETED</span> <span class="material-symbols-rounded action">check_circle</span>
                    </div>
                  </button>
                </div>
              </div>
            </mat-card-content>

          </mat-card>
        </div>
        <div *ngFor="let t of liveTrainings" fxFlex fxLayoutGap="10px">
          <mat-card fxLayoutGap="10px" fxLayout="row">
            <mat-card-content fxLayoutGap="10px">
              <div fxFlex="30%">
                <img src='assets/{{t.img}}/thumbnail.jpg' alt=""/>
              </div>
              <div fxFlex fxLayout="column" fxLayoutGap="2px">
                <div><span class="title">{{t.displayName}}</span></div>
                <div><span class="label">LIVE WEBINAR</span></div>
                <div><fa-icon [icon]="faCalendar"></fa-icon> {{t.start?.toISOString() | date:"dd MMM YYYY h:mm a (Z)"}}</div>
                <div><fa-icon [icon]="faClock"></fa-icon> Duration {{t.durationMs / 1000 / 60}} mins</div>
                <div><hr></div>
                <div fxFlex fxLayoutGap="10px">
                  <button mat-raised-button color="brown-orange" (click)="register(t)" *ngIf="!t.registered" [ladda]="t.submitting">
                    REGISTER
                  </button>
                  <button mat-raised-button color="gray" *ngIf="t.registered && !t.attendLink" disabled>
                    ALREADY REGISTERED
                  </button>
                  <a mat-raised-button color="green" *ngIf="t.registered && t.attendLink" (click)="join(t)">
                    JOIN SESSION
                  </a>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
  <div fxFlex="20%" fxFlex.lt-sm="5px"></div>
</div>

<app-modal [id]="modalId">
  <div class="container" fxLayout fxLayoutGap="10px" fxLayoutAlign="center">
    <div fxFlex="30%" fxFlex.xs="10px"></div>
    <div fxFlex="grow" fxLayout="column">
      <mat-card>
        <mat-card-content fxLayout="column" fxLayoutGap="10px">
          <mat-card-title>
            <h2 class="section-heading">Confirmation</h2>
          </mat-card-title>
          <form fxLayout="column" fxLayoutGap="20px">
            <div>
              I acknowledge that I have completed the Yoga Veera Training.
            </div>
            <div fxFlex fxLayoutAlign="center" class="button-row" fxLayoutGap="20px">
              <button color="primary" fxFlex="initial" [ladda]="submitting" mat-raised-button type="button" (click)="submit()">
                SUBMIT
              </button>
              <button mat-raised-button (click)="closeModal()" type="button">
                CLOSE
              </button>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
      <div fxFlex="20%" fxFlex.xs="10px"></div>
    </div>
    <div fxFlex="30%" fxFlex.xs="10px"></div>
  </div>
</app-modal>
