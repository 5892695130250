<div class="container" fxLayout fxLayoutGap="32px" fxLayoutAlign="center">
  <div fxFlex="20%" fxHide.lt-sm></div>
  <div fxFlex fxLayout="column" fxLayoutGap="20px">
    <div></div>
    <h1>Promote Your Session</h1>
    <mat-accordion>
      <mat-expansion-panel *ngFor="let p of promo">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{p.title}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row wrap" fxLayoutGap="20px grid">
          <div *ngFor="let image of p.images" fxFlex="200px" fxLayout="column">
            <div>
              <a href="{{server}}/{{image.download}}" download>
                <img [src]="image.img" alt="" class="img-responsive"/>
              </a>
            </div>
            <div>
              <a href="{{server}}/{{image.download}}" download>DOWNLOAD</a>
            </div>
          </div>
          <div *ngFor="let file of p.files">
            <a class="small" mat-raised-button href="{{server}}/{{file.download}}" download>
              {{file.title}}
            </a>
          </div>
          <div *ngFor="let file of p.links">
            <a class="small" mat-raised-button href="{{file.link}}" target="_blank">
              {{file.title}}
            </a>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div fxFlex="20%" fxHide.lt-sm></div>
</div>
