<mat-form-field fxFill>
  <mat-select [formControl]="tzCtrl" placeholder="Timezone" #tzSelect required>
    <mat-option>
      <ngx-mat-select-search [formControl]="tzFilterCtrl" placeholderLabel="Search" noEntriesFoundLabel="Not Found"></ngx-mat-select-search>
    </mat-option>

    <mat-option *ngFor="let tz of filteredTZ | async" [value]="tz">
      {{tz}}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="tzCtrl.hasError('required')">Required</mat-error>
</mat-form-field>
