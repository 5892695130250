import {Component, OnInit} from '@angular/core';
import {BackendService} from '../shared/backend.service';
import {AuthService} from '../auth/auth.service';
import {Router} from '@angular/router';
import {Training} from '../shared/training.model';
import * as moment from "moment";
import {faCalendarAlt, faCircleCheck, faClock, faHourglass} from "@fortawesome/free-solid-svg-icons";
import {User} from "../auth/user.model";
import {PlatformLocation} from "@angular/common";
import {ModalService} from "../shared/modal";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  trainings: Training[] = [];
  liveTrainings: Training[] = [];
  completed = false;
  user?: User | null;
  modalId = 'already-completed';
  submitting = false;
  training?: Training;

  constructor(private backend: BackendService, private authService: AuthService, private router: Router,
              private platformLocation: PlatformLocation, private modal: ModalService) {
  }

  ngOnInit() {
    this.user = this.authService.getUser();
    if (this.user?.memberId === 0) {
      this.router.navigate(['/create']).then();
    } else {
      this.query();
    }
  }

  query() {
    this.backend.get('trainings')
      .subscribe((resp: any) => {
        this.trainings = [];
        resp.forEach((r: any) => {
          this.trainings.push({
            ...r,
            img: 'iuyt',
            attendLink: `/recorded-training/${r.id}`,
            hideAt: moment(r.hideAt),
            hide: moment().isAfter(r.hideAt),
          })

          if (r.participant?.completedAt) {
            this.completed = true;
          }
        });
      });
  }

  setSessionUrl(tr: Training) {
    // tr.start = moment().add(10, "minutes");

    if (tr.start!.diff(moment(), 'minutes') > 30) {
      return
    }

    this.backend.api('portal.getWebinarRegistrations', {programIds: tr.id})
      .subscribe((rsp: any) => {
          if (rsp.statusCode === 'SUCCESS') {
            tr.attendLink = rsp.object[0].externalUrl;
          }
        }
      );
  }

  register(tr: Training) {
    tr.submitting = true;
    this.backend.api('portal.register', {
      programId: tr.id,
      memberId: this.user?.memberId,
      successCallbackUrl: this.location()
    })
      .subscribe((rsp: any) => {
          if (rsp.statusCode === 'SUCCESS') {
            tr.registered = true;
          }
          tr.submitting = false;
        }
      );
  }

  join(tr: Training) {
    window.location.href = <string>tr.attendLink;
  }

  location(): string {
    let l = `${this.platformLocation.protocol}//${this.platformLocation.hostname}`;
    if (this.platformLocation.port) {
      l += `:${this.platformLocation.port}`
    }

    return l
  }


  openModal(t: Training) {
    this.training = t;
    this.modal.open(this.modalId);
  }

  submit() {
    this.submitting = true;
    this.backend.post(`training/${this.training?.id}`).subscribe(() => {
      this.backend.post(`training/${this.training?.id}/progress`, {currentPositionMs: this.training?.durationMs})
        .subscribe((resp: any) => {
          this.submitting = false;
          this.completed = true;
          this.closeModal();
        });
    });
  }

  closeModal() {
    this.modal.close(this.modalId);
  }

  protected readonly faCalendar = faCalendarAlt;
  protected readonly faClock = faClock;
  protected readonly faHourglass = faHourglass;
  protected readonly moment = moment;
  protected readonly faCircleCheck = faCircleCheck;
}
