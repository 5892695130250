import {Component, OnInit} from '@angular/core';
import {Moment} from "moment/moment";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Session} from "../../shared/session.model";
import {BackendService} from "../../shared/backend.service";
import {ActivatedRoute, Router} from "@angular/router";
import * as moment from "moment/moment";

@Component({
  selector: 'app-create-edit-session',
  templateUrl: './create-edit-session.component.html',
  styleUrls: ['./create-edit-session.component.scss']
})
export class CreateEditSessionComponent implements OnInit {
  form: FormGroup;
  sessionId: number | undefined;
  action: string = "REGISTER";
  submitting = false;

  programTypes = [
    'Yoga for Mental Health',
    'Yoga for Wellbeing',
    'Inner Engineering Intro with Isha Kriya'
  ];

  languages = [
    'English',
    'Mandarin 中文',
    'Cantonese 廣東話',
    'Bahasa Indonesia',
    'Tiếng Việt',
    'Japanese 日本語',
    'Thai แบบไทย',
    'Korean 한국어',
    'Khmer',
  ];

  constructor(private backend: BackendService, private fb: FormBuilder, private router: Router,
              private route: ActivatedRoute) {
    this.form = this.initForm();
  }

  ngOnInit() {
    this.sessionId = this.route.snapshot.params['id'];
    if (this.sessionId) {
      this.backend.get(`program-ext/${this.sessionId}`).subscribe((rsp: any) => {
        this.createEditSession(rsp);
      });
    } else {
      this.createEditSession();
    }
  }

  initForm() {
    return this.fb.group({
      sessionDateTime: ['', [Validators.required]],
      timezone: ['', [Validators.required]],
      programType: ['', [Validators.required]],
      privateSession: ['', []],
      language: ['', [Validators.required]],
      venueDetails: ['', [Validators.required]],
      country: ['', [Validators.required]],
      postalCode: ['', [Validators.required]],
    });
  }

  programType(d: any) {
    if (d.programTypeId === 209) {
      return 'Yoga for Mental Health';
    }

    if (d.programTypeId === 108) {
      return 'Inner Engineering Intro with Isha Kriya';
    }

    return 'Yoga for Wellbeing';
  }

  createEditSession(session: any = null) {
    if (session) {
      this.sessionId = session.id;
      this.action = "UPDATE";

      this.form.patchValue({
        sessionDateTime: moment(session.sessionTimings[0].start * 1000),
        timezone: session.timeZone,
        programType: this.programType(session.programTypeId),
        privateSession: session.privateSession,
        language: session.language,
        venueDetails: session.venueDetails,
        country: session.country,
        postalCode: session.postalCode,
      });
    } else {
      this.form.patchValue({
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        privateSession: true,
      });

      this.action = "REGISTER";
    }

    this.submitting = false;
  }

  onSubmit() {
    const m: Moment = this.form.value.sessionDateTime;
    let duration: number = 45;
    if (this.form.value.programType == 'Yoga for Wellbeing') {
      duration = 60;
    }

    const data = {
      programType: this.form.value.programType,
      schedule: [{
        start: m.unix(),
        duration,
      }],
      privateSession: this.form.value.privateSession || false,
      timeZone: this.form.value.timezone,
      venueDetails: this.form.value.venueDetails,
      country: this.form.value.country,
      postalCode: this.form.value.postalCode,
      language: this.form.value.language,
    };

    let endpoint = "program-ext";
    if (this.sessionId) {
      endpoint += `/${this.sessionId}`;
    }

    this.submitting = true;
    this.backend.post(endpoint, data).subscribe((rsp: any) => {
      this.submitting = false;
      this.router.navigate(['/sessions']).then();
    });
  }
}
