import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface BtnCellRendererParams<T> extends ICellRendererParams {
  buttonText: string;
  buttonTextDynamic(data: T): string;
  clicked(data: T): void;
  hideIcon?: boolean;
  buttonDisabled: boolean;
  buttonDisabledDynamic(data: T): boolean;
}

@Component({
  selector: 'app-btn-cell-renderer',
  templateUrl: './btn-cell-renderer.component.html',
  styleUrls: ['./btn-cell-renderer.component.scss']
})
export class BtnCellRendererComponent<T> implements ICellRendererAngularComp {
  showIconCSS: '' | 'None' = '';
  public params?: BtnCellRendererParams<T>;

  agInit(params: BtnCellRendererParams<T>): void {
    this.params = params;
    this.showIconCSS = this.params.hideIcon ? 'None' : '';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refresh(params: BtnCellRendererParams<T>): boolean {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  btnClickedHandler($event: MouseEvent): void {
    this.params?.clicked(this.params.data);
  }

  get btnTextDynamic(): string {
    return <string>this.params?.buttonTextDynamic?.(this.params?.data);
  }
}
