<div class="container" fxLayout fxLayoutGap="32px" fxLayoutAlign="center">
  <div fxFlex="20%" fxHide.lt-sm></div>
  <div fxFlex fxLayout="column" fxLayoutGap="20px">
    <div></div>
    <h1>Support Materials</h1>
    <mat-accordion>
      <mat-expansion-panel *ngFor="let item of items" [expanded]="item.type === 'Documents'">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{item.type}} - {{item.lang}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row wrap" fxLayoutGap="20px grid">
          <div *ngFor="let f of item.files" fxFlex="170px">
            <mat-card class="gray" fxLayout="column" fxLayoutGap="4px">
              <mat-card-content>
                <div><strong>{{f.title}}</strong></div>
                <div class="duration" *ngIf="f.duration" fxLayout>
                  <div class="label">{{f.duration}}</div>
                  <div fxFlex="auto"></div>
                </div>
                <div class="red" *ngIf="f.pdf"><small>PDF</small></div>
                <div class="red" *ngIf="f.zip"><small>ZIP</small></div>
                <div>
                  <button class="small" mat-raised-button color="brown-orange" (click)="download(item, f)">
                    DOWNLOAD
                  </button>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div fxFlex="20%" fxHide.lt-sm></div>
</div>

