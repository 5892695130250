<mat-toolbar color="primary">
  <div class="logo">
    <a routerLink=""><img src="../../../assets/header/header-logo.png" alt="logo"></a>
  </div>
  <div #headerItems fxFlex fxLayout fxLayoutGap="5px" fxLayoutAlign="end" *ngIf="user">
    <div routerLink="" class="icon-text">
      <mat-icon class="pad">home</mat-icon>
      <span>HOME</span>
    </div>
    <div class="icon-text" [matMenuTriggerFor]="menu">
      <mat-icon class="pad">account_circle</mat-icon>
      <span>{{user.firstName}}</span>
      <mat-icon>expand_more</mat-icon>
    </div>
    <mat-menu #menu="matMenu">
<!--      <button mat-menu-item routerLink="profile">Profile</button>-->
      <button mat-menu-item (click)="logout()">Logout</button>
    </mat-menu>
  </div>
</mat-toolbar>
<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<!--<mat-progress-bar mode="determinate" value="100" *ngIf="!loading"></mat-progress-bar>-->
