import {Component, Input, OnInit} from '@angular/core';

import {Resource, ResourceFile, RESOURCES} from '../../session-kit/resources';
import {DisclaimerDialogComponent} from '../../session-kit/disclaimer-dialog/disclaimer-dialog.component';

import {BackendService} from '../../shared/backend.service';
import {ApiResponseModel} from '../../shared/api-response.model';
import {DownloadDialogComponent} from '../../session-kit/download-dialog/download-dialog.component';
import {HOSTED_URL, PROMO_MATERIALS} from '../../promote/promo-materials';
import {NgForm} from '@angular/forms';
import {AuthService} from '../../auth/auth.service';
import {finalize} from 'rxjs/operators';
import {Training} from '../../shared/training.model';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-post-training-iuy',
  templateUrl: './iuy.component.html',
  styleUrls: ['./iuy.component.scss']
})
export class PostTrainingIuyComponent implements OnInit {
  step?: string;
  @Input() training?: Training;


  promo = PROMO_MATERIALS;
  server = HOSTED_URL;
  isSubmitting = false;
  submitted = false;

  constructor(public dialog: MatDialog, private backendService: BackendService, private authService: AuthService) {
  }

  ngOnInit() {
  }

  openDownloadDialog(item: Resource, file: ResourceFile): void {
    if (!this.training!.participant?.postSessionConfirmedAt) {
      this.openDisclaimerDialog(item, file);
      return;
    }

    this.dialog.open(DownloadDialogComponent, {
      width: '500px',
      data: { item, file }
    });
  }

  openDisclaimerDialog(item: Resource, file: ResourceFile): void {
    const dialogRef = this.dialog.open(DisclaimerDialogComponent, {
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.backendService.api('webinar.updatePostSessionConfirmation', { webinarId: this.training?.id })
          .subscribe((resp: any) => {
            this.training!.participant = resp.object;
            this.openDownloadDialog(item, file);
          });
      }
    });
  }

  onHostSessionSubmit(f: NgForm) {

    const user = this.authService.getUser();
    const params = {
      startDate: f.value.sessionDateTime.format('DD/MM/YYYY'),
      endDate: f.value.sessionDateTime.format('DD/MM/YYYY'),
      programType: 'Isha Upa Yoga',
      duration: f.value.duration.replace(' mins', ''),
      language: f.value.language,
      address: f.value.streetAddress,
      areaLocality: f.value.city,
      cityState: f.value.stateProvince,
      postalCode: f.value.postalCode,
      country: f.value.country,
      contactInfo: f.value.contactInfo,
      yourName: user?.firstName + ' ' + user?.lastName,
      sessionReference: 'IND-IUY-' + Date.now() + Math.random().toString(36).slice(-5)
    };

    this.isSubmitting = true;
    this.backendService.gformSubmit('AKfycbyQR7QvaMW0Dv_fSVpgVmCnPbtIOxfMq2I_vE9qXA', params)
      .pipe(
        finalize(() => { this.isSubmitting = false; })
      )
      .subscribe(() => {
        this.submitted = true;
      });
  }

  onClearForm(f: NgForm) {
    f.resetForm();
    this.submitted = false;
  }


}
