import {NgModule} from '@angular/core';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MAT_DATETIME_FORMATS, MatDatetimepickerModule} from '@mat-datetimepicker/core';
import {MatMomentDatetimeModule} from '@mat-datetimepicker/moment';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatMenuModule} from '@angular/material/menu';
import {MatRippleModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";

@NgModule({
  imports: [
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatMenuModule,
    MatRippleModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatCardModule,
    MatExpansionModule,
    MatChipsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatMomentDatetimeModule,
    MatDatetimepickerModule,
    MatSlideToggleModule,
  ],
  exports: [
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatMenuModule,
    MatRippleModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatCardModule,
    MatExpansionModule,
    MatChipsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatMomentDatetimeModule,
    MatDatetimepickerModule,
    MatSlideToggleModule,
  ],
  providers: [
    {
      provide: MAT_DATETIME_FORMATS,
      useValue: {
        parse: {
          dateInput: 'ddd, D MMM YYYY',
          monthInput: 'MMMM',
          timeInput: 'LT',
          datetimeInput: 'ddd, D MMM YYYY LT'
        },
        display: {
          dateInput: 'ddd, D MMM YYYY',
          monthInput: 'MMMM',
          datetimeInput: 'ddd, D MMM YYYY LT',
          timeInput: 'LT',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
          popupHeaderDateLabel: 'ddd, DD MMM'
        }
      }
    }
  ]
})
export class MaterialModule {
}
