import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AppComponent} from './app.component';
import {MaterialModule} from './material.module';
import {LoginComponent} from './auth/login/login.component';
import {HomeComponent} from './home/home.component';
import {AppRoutingModule} from './app-routing.module';
import {HeaderComponent} from './nav/header/header.component';
import {LaddaModule} from 'angular2-ladda';
import {HttpClientModule} from '@angular/common/http';
import {NotificationComponent} from './shared/notification/notification.component';
import {CreateComponent} from './create/create.component';
import {CountriesComponent} from './shared/countries/countries.component';
import {ReconcileComponent} from './reconcile/reconcile.component';
import {RecordedTrainingComponent} from './trainings/recorded-training/recorded-training.component';
import {PostTrainingIuyComponent} from './post-training/iuy/iuy.component';
import {DownloadDialogComponent} from './session-kit/download-dialog/download-dialog.component';
import {DisclaimerDialogComponent} from './session-kit/disclaimer-dialog/disclaimer-dialog.component';
import {faFacebookF, faGoogle} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeModule, FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {SessionsComponent} from './sessions/sessions.component';
import {ModalModule} from './shared/modal';
import {LoginSuccessComponent} from './auth/login-success/login-success.component';
import {MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef} from '@angular/material/snack-bar';
import {SessionKitComponent} from "./session-kit/session-kit.component";
import {PromoteComponent} from "./promote/promote.component";
import {TimezonesComponent} from "./shared/timezones/timezones.component";
import {MatTableModule} from "@angular/material/table";
import {AgGridModule} from 'ag-grid-angular';
import {SupportKitComponent} from "./support-kit/support-kit.component";
import {CreateEditSessionComponent} from './sessions/create-edit-session/create-edit-session.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ReconcileComponent,
    HomeComponent,
    HeaderComponent,
    NotificationComponent,
    CreateComponent,
    CountriesComponent,
    RecordedTrainingComponent,
    PostTrainingIuyComponent,
    DownloadDialogComponent,
    DisclaimerDialogComponent,
    SessionsComponent,
    LoginSuccessComponent,
    SessionKitComponent,
    SupportKitComponent,
    PromoteComponent,
    TimezonesComponent,
    CreateEditSessionComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    FlexLayoutModule,
    FormsModule,
    LaddaModule,
    HttpClientModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    AgGridModule,
    ModalModule,
    MatTableModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: MatSnackBarRef, useValue: {}},
    {provide: MAT_SNACK_BAR_DATA, useValue: {}}
  ],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faFacebookF, faGoogle);
  }
}
