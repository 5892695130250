import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {BackendService} from '../../shared/backend.service';
import {NotificationService} from '../../shared/notification/notification.service';
import {Training} from '../../shared/training.model';
import {AuthService} from '../../auth/auth.service';
import * as moment from "moment";

declare var jwplayer: any;

@Component({
  selector: 'app-training',
  templateUrl: './recorded-training.component.html',
  styleUrls: ['./recorded-training.component.scss']
})
export class RecordedTrainingComponent implements OnInit {
  training: Training | null = null;
  player: any;
  trainingId: string;

  constructor(private backend: BackendService,
              private route: ActivatedRoute,
              private router: Router,
              private auth: AuthService,
              private ntf: NotificationService) {
    this.trainingId = this.route.snapshot.params['id'];
  }

  ngOnInit() {
    const trainingId = 1;
    this.backend.get(`training/${this.trainingId}`)
      .subscribe(
        (rsp: any) => {
          this.training = rsp;
          if (!this.training?.participant) {
            this.backend.post(`training/${this.trainingId}`).subscribe();
          } else {
            this.training.participant.currentTime = moment.utc(this.training.participant.currentPositionMs).format('HH:mm:ss');
            this.training.participant.started = this.training.participant.currentPositionMs > 0;
          }
        },
        rsp => {
          this.ntf.error(`Error: ${rsp.userFriendlyMessage}`);
        }
      );
  }

  saveProgress() {
    if (this.player.getState() !== 'playing') {
      return;
    }

    console.log(`state ${this.player.getState()}, pos:${this.player.getPosition()}`);

    const data = {
      currentPositionMs: Math.ceil(this.player.getPosition() * 1000)
    };

    this.backend.post(`training/${this.training?.id}/progress`, data).subscribe();
  }

  startSession(resume: boolean) {
    this.player = jwplayer('player');
    this.player.setup({
      file: this.training?.mediaUrl,
      mediaid: this.mediaId(<string>this.training?.mediaUrl)
    });

    this.player.on('play', () => {
      if (!resume) {
        // if playing from start, call saveProgress once to set the startMs on the backend
        this.saveProgress();
      }

      if (!this.player.timer) {
        this.player.timer = setInterval(() => this.saveProgress(), 60 * 1000); // save progress every minute
      }
    });

    this.player.on('pause', () => {
      this.saveProgress();
    });

    this.player.on('complete', () => {
      this.saveProgress();
      this.cancelTimer();
      setTimeout(() => {
        this.router.navigate(['/post-training', this.training?.id]).then();
      }, 500);
    });

    this.player.on('ready', () => {
      if (resume && this.training?.participant?.currentPositionMs) {
        this.player.seek(this.training.participant.currentPositionMs / 1000);
      }
      this.player.play();
      this.saveProgress();
    });
  }

  cancelTimer() {
    if (this.player.timer) {
      clearInterval(this.player.timer);
      this.player.timer = null;
    }
  }

  mediaId(url: string) {
    const s = url.substr(url.lastIndexOf('/') + 1);
    return s.substr(0,s.lastIndexOf('.'));
  }

}
