<mat-form-field fxFill>
  <mat-select [formControl]="countryCtrl" placeholder="Country" #countrySelect required>
    <mat-option>
      <ngx-mat-select-search [formControl]="countryFilterCtrl" placeholderLabel="Search" noEntriesFoundLabel="Not Found"></ngx-mat-select-search>
    </mat-option>

    <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
      {{country.name}}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="countryCtrl.hasError('required')">Required</mat-error>
</mat-form-field>
