import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './auth/login/login.component';
import {AuthGuard} from './auth/auth.guard';
import {ReconcileComponent} from './reconcile/reconcile.component';
import {CreateComponent} from './create/create.component';
import {RecordedTrainingComponent} from './trainings/recorded-training/recorded-training.component';
import {SessionsComponent} from './sessions/sessions.component';
import {LoginSuccessComponent} from './auth/login-success/login-success.component';
import {SessionKitComponent} from "./session-kit/session-kit.component";
import {PromoteComponent} from "./promote/promote.component";
import {SupportKitComponent} from "./support-kit/support-kit.component";
import {CreateEditSessionComponent} from "./sessions/create-edit-session/create-edit-session.component";

const routes: Routes = [
  {path: '', pathMatch: 'full', component: HomeComponent, canActivate: [AuthGuard]},

  {path: 'login', component: LoginComponent},
  {path: 'login/:key', component: LoginSuccessComponent, pathMatch: 'full'},

  {path: 'create', component: CreateComponent, canActivate: [AuthGuard]},
  {path: 'reconcile', component: ReconcileComponent, canActivate: [AuthGuard]},

  {path: 'recorded-training/:id', component: RecordedTrainingComponent, canActivate: [AuthGuard]},

  {path: 'session-kit', component: SessionKitComponent, canActivate: [AuthGuard]},
  {path: 'support-kit', component: SupportKitComponent, canActivate: [AuthGuard]},
  {path: 'promote', component: PromoteComponent, canActivate: [AuthGuard]},
  {path: 'sessions', component: SessionsComponent, canActivate: [AuthGuard]},
  {path: 'session/create', component: CreateEditSessionComponent, canActivate: [AuthGuard]},
  {path: 'session/edit/:id', component: CreateEditSessionComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {
}
