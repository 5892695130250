export interface PromoImageMaterial {
  img: string;
  download: string;
}

export interface PromoFileMaterial {
  title: string;
  download: string;
}

export interface PromoLinkMaterial {
  title: string;
  link: string;
}

export interface PromoMaterialCategory {
  title: string;
  images?: PromoImageMaterial[];
  files?: PromoFileMaterial[];
  links?: PromoLinkMaterial[];
}

export const HOSTED_URL = 'https://storage.googleapis.com/files.ishayoga.sg';

export const PROMO_MATERIALS: PromoMaterialCategory[] = [
  {
    title: 'Animations',
    images: [
      {img: '/assets/iuyt/promo/gif/aim.gif', download: 'iuy/promo/gif/aim.gif'},
      {img: '/assets/iuyt/promo/gif/anger.gif', download: 'iuy/promo/gif/anger.gif'},
      {img: '/assets/iuyt/promo/gif/clarity.gif', download: 'iuy/promo/gif/clarity.gif'},
      {img: '/assets/iuyt/promo/gif/freedom.gif', download: 'iuy/promo/gif/freedom.gif'},
      {img: '/assets/iuyt/promo/gif/life.gif', download: 'iuy/promo/gif/life.gif'},
      {img: '/assets/iuyt/promo/gif/patience.gif', download: 'iuy/promo/gif/patience.gif'},
      {img: '/assets/iuyt/promo/gif/peace.gif', download: 'iuy/promo/gif/peace.gif'}
    ]
  },
  {
    title: 'Myths About Yoga',
    images: [
      {img: '/assets/iuyt/promo/myths/headstand.jpg', download: 'iuy/promo/myths/5_headstand.jpg'},
      {img: '/assets/iuyt/promo/myths/hindu.jpg', download: 'iuy/promo/myths/3_hindu.jpg'},
      {img: '/assets/iuyt/promo/myths/music.jpg', download: 'iuy/promo/myths/4_music.jpg'},
      {img: '/assets/iuyt/promo/myths/posture.jpg', download: 'iuy/promo/myths/1_posture.jpg'}]
  },
  {
    title: 'Facts on Yoga',
    images: [
      {img: '/assets/iuyt/promo/facts/1.png', download: 'iuy/promo/facts/1.png'},
      {img: '/assets/iuyt/promo/facts/2.jpg', download: 'iuy/promo/facts/2.jpg'},
      {img: '/assets/iuyt/promo/facts/3.png', download: 'iuy/promo/facts/3.png'},
      {img: '/assets/iuyt/promo/facts/4.jpg', download: 'iuy/promo/facts/4.jpg'},
      {img: '/assets/iuyt/promo/facts/5.png', download: 'iuy/promo/facts/5.png'},
      {img: '/assets/iuyt/promo/facts/6.jpg', download: 'iuy/promo/facts/6.jpg'},
      {img: '/assets/iuyt/promo/facts/7.png', download: 'iuy/promo/facts/7.png'}]
  },
  {
    title: 'Yoga Questions and Answers',
    images: [
      {img: '/assets/iuyt/promo/qna/4-Paths.jpg', download: 'iuy/promo/qna/4-Paths.jpg'},
      {img: '/assets/iuyt/promo/qna/Breathe-During-Asanas.jpg', download: 'iuy/promo/qna/Breathe-During-Asanas.jpg'},
      {img: '/assets/iuyt/promo/qna/Close-Your-Eyes.jpg', download: 'iuy/promo/qna/Close-Your-Eyes.jpg'},
      {img: '/assets/iuyt/promo/qna/Dont-Eat.jpg', download: 'iuy/promo/qna/Dont-Eat.jpg'},
      {img: '/assets/iuyt/promo/qna/First-Yogi.jpg', download: 'iuy/promo/qna/First-Yogi.jpg'},
      {img: '/assets/iuyt/promo/qna/Its-Yoga-Time.jpg', download: 'iuy/promo/qna/Its-Yoga-Time.jpg'},
      {img: '/assets/iuyt/promo/qna/Kundalini.jpg', download: 'iuy/promo/qna/Kundalini.jpg'},
      {img: '/assets/iuyt/promo/qna/What-is-Yoga.jpg', download: 'iuy/promo/qna/What-is-Yoga.jpg'},
      {img: '/assets/iuyt/promo/qna/Yoga-Practice.jpg', download: 'iuy/promo/qna/Yoga-Practice.jpg'},
      {img: '/assets/iuyt/promo/qna/Yoga-Practice-Time.jpg', download: 'iuy/promo/qna/Yoga-Practice-Time.jpg'}]
  },
  {
    title: 'Editable PDFs',
    files: [
      {title: 'Brochure', download: 'iuy/promo/print/Brochure.pdf'},
      {title: 'Corporate Eflyer', download: 'iuy/promo/print/Eflyer-Corporate.pdf'},
      {title: 'Public Eflyer', download: 'iuy/promo/print/Eflyer-Public.pdf'},
      {title: 'Flyer', download: 'iuy/promo/print/Flyer.pdf'},
      {title: 'Poster', download: 'iuy/promo/print/Poster.pdf'},
      {title: 'Standee', download: 'iuy/promo/print/Standee.pdf'}
    ]
  },
  {
    title: 'Editable Creatives',
    links: [
      {title: 'Yoga for Mental Health', link: 'https://isha.to/yv/canva/yoga-for-mental-health'},
      {title: 'Yoga for Wellbeing', link: 'https://isha.to/yv/canva/yoga-for-wellbeing'},
      {title: 'Introduction to Inner Engineering', link: 'https://isha.to/yv/canva/introduction-to-inner-engineering'},
    ]
  }
];
