<div class="container" fxLayout fxLayoutGap="32px" fxLayoutAlign="center">
  <div fxFlex="20%" fxHide.lt-sm></div>
  <div fxFlex fxLayout="column" fxLayoutGap="10px" *ngIf="training">
    <div class="heading">
      <h2>{{training.displayName}}</h2>
    </div>
    <div>
      <h3>Guidelines</h3>
      <ul>
        <li>Please ensure you allocate one and half hour of undisturbed time to complete the training.</li>
        <li>You will receive the material to host the sessions, after you complete this training session.</li>
        <li>For any questions about the training or about hosting sessions, please write to&nbsp;<a href="mailto:ishanga.apac@ishayoga.org">ishanga.apac@ishayoga.org</a></li>
      </ul>
    </div>
    <div fxLayoutAlign="center center" fxLayoutGap="20px" *ngIf="!player">
      <button mat-raised-button color="green" (click)="startSession(false)" *ngIf="!training.participant?.currentPositionMs">
        START SESSION
      </button>
      <button mat-raised-button color="green" (click)="startSession(true)" *ngIf="training.participant?.started">
        RESUME SESSION
        <span class="small">[{{training.participant!.currentTime}}]</span>
      </button>
      <button mat-raised-button color="blue" (click)="startSession(false)" *ngIf="training.participant?.started">
        RESTART SESSION
      </button>
    </div>
    <div>
      <video id="player" controls crossorigin playsinline fxFlex fxFill [hidden]="!player"></video>
    </div>
    <div fxFlex fxLayout="column" fxLayoutAlign="center center">
      <hr>
      <button mat-raised-button color="brown-orange" routerLink="" *ngIf="training.participant?.completedAt">
        VIEW RESOURCES
      </button>
    </div>

  </div>
  <div fxFlex="20%" fxHide.lt-sm></div>
</div>

