export interface Resource {
  lang: string;
  type: string;
  files?: ResourceFile[];
}

export interface ResourceFile {
  title: string;
  zip?: boolean;
  pdf?: boolean;
  link?: string;
  duration?: string;
  media?: MediaInfo[];
}

export interface MediaInfo {
  r?: string;
  size: string;
  link: string;
}

export const RESOURCES: Resource[] = [
  {
    lang: 'English',
    type: 'Documents',
    files: [
      {
        title: 'Invitation for First Session Template',
        pdf: true,
        link: 'https://storage.googleapis.com/files.ishayoga.sg/iuy/en/invitation-for-first-session-template.pdf'
      },
      {
        title: 'Invitation for Correction Session Template',
        pdf: true,
        link: 'https://storage.googleapis.com/files.ishayoga.sg/iuy/en/invitation-for-first-correction-template.pdf'
      },
      {
        title: 'Post Session Email',
        pdf: true,
        link: 'https://storage.googleapis.com/files.ishayoga.sg/iuy/en/post-session-email.pdf'
      },
      {
        title: 'Session Setup Guidelines',
        pdf: true,
        link: 'https://storage.googleapis.com/files.ishayoga.sg/iuy/en/session-setup-guidelines.pdf'
      },
      {
        title: 'Promotion Guide',
        pdf: true,
        link: 'https://storage.googleapis.com/files.ishayoga.sg/iuy/en/promotion-guide.pdf'
      }
    ]
  },
  {
    lang: 'English',
    type: 'Video',
    files: [
      {
        title: 'Zoom Tutorial',
        duration: '9mins',
        link: 'https://storage.googleapis.com/files.ishayoga.sg/iuy/en/zoom-tutorial-v4.mp4'
      },
    ]
  },
];
