import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TimeZones} from './timezones.data';
import {ReplaySubject, Subject} from 'rxjs';

import {take, takeUntil} from 'rxjs/operators';
import {MatSelect} from '@angular/material/select';

@Component({
  selector: 'app-timezones',
  templateUrl: './timezones.component.html',
  styleUrls: ['./timezones.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TimezonesComponent,
      multi: true
    }
  ]
})
export class TimezonesComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  protected timezones: string[] = TimeZones;
  public tzCtrl: FormControl = new FormControl();
  public tzFilterCtrl: FormControl = new FormControl();
  public filteredTZ: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  @ViewChild('tzSelect', {static: false}) tzSelect?: MatSelect;

  protected onDestroy = new Subject<void>();
  private onChange?: (_: any) => void;
  private onTouched?: (_: any) => void;

  constructor() {
  }

  ngOnInit() {
    this.filteredTZ.next(this.timezones.slice());

    this.tzCtrl.valueChanges
      .subscribe(() => {
        if (this.onChange) {
          this.onChange(this.tzCtrl.value);
        }
      });

    this.tzFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  protected setInitialValue() {
    this.filteredTZ
      .pipe(take(1), takeUntil(this.onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredTZ are loaded initially
        // and after the mat-option elements are available
        this.tzSelect!.compareWith = (a: string, b: string) => a === b;
      });
  }

  protected filterBanks() {
    if (!this.timezones) {
      return;
    }

    let search = this.tzFilterCtrl.value;
    if (!search) {
      this.filteredTZ.next(this.timezones.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredTZ.next(
      this.timezones.filter(v => v.toLowerCase().indexOf(search) > -1)
    );
  }

  writeValue(tz: string) {
    if (tz === null) {
      this.tzCtrl.setValue(null);
    } else {
      const found = this.timezones.find((v) => v === tz);
      if (found) {
        this.tzCtrl.setValue(found);
      }
    }
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
