import {Component, OnInit} from '@angular/core';
import {Resource, ResourceFile, RESOURCES} from "./resources";
import {DownloadDialogComponent} from "./download-dialog/download-dialog.component";
import {DisclaimerDialogComponent} from "./disclaimer-dialog/disclaimer-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {BackendService} from "../shared/backend.service";
import {Training} from "../shared/training.model";
import {Router} from "@angular/router";

@Component({
  selector: 'app-session-kit',
  templateUrl: './session-kit.component.html',
  styleUrls: ['./session-kit.component.scss']
})
export class SessionKitComponent implements OnInit {
  items = RESOURCES;
  training?: Training | null;

  constructor(public dialog: MatDialog, private backend: BackendService, private router: Router) {
  }

  ngOnInit() {
    this.query();
  }

  openDownloadDialog(item: Resource, file: ResourceFile): void {
    // if (!this.training!.participant?.postSessionConfirmedAt) {
    //   this.openDisclaimerDialog(item, file);
    //   return;
    // }

    this.dialog.open(DownloadDialogComponent, {
      width: '500px',
      data: {item, file}
    });
  }
  //
  // openDisclaimerDialog(item: Resource, file: ResourceFile): void {
  //   const dialogRef = this.dialog.open(DisclaimerDialogComponent, {
  //     width: '500px'
  //   });
  //
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       this.backend.post('training/1/post-session')
  //         .subscribe((resp: any) => {
  //           this.training!.participant = resp.object;
  //           this.openDownloadDialog(item, file);
  //         });
  //     }
  //   });
  // }

  query() {
    this.backend.get('trainings?completed=true&name=onlineyogaveeratraining', {})
      .subscribe((resp: any) => {
        if (!resp?.length) {
          this.router.navigate(['']).then();
        }
      });
  }
}
