import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {Subscription} from 'rxjs';
import {BackendService} from '../../shared/backend.service';
import {User} from '../../auth/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  user?: User | null;
  authSub: Subscription;
  loadingSub: Subscription;
  loading = false;

  constructor(private authService: AuthService, private backendService: BackendService) {
    this.authSub = this.authService.authChange.subscribe(authStatus => {
      console.log('auth changed ' + authStatus);
      if (authStatus) {
        this.authService.checkAuth().subscribe(isAuth => {
          this.user = this.authService.getUser();
          console.log(this.user);
        });
      } else {
        this.user = null;
      }
    });
    this.loadingSub = this.backendService.loadingChange.subscribe(loading => {
      console.log(loading);
      this.loading = loading;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.authSub.unsubscribe();
    this.loadingSub.unsubscribe();
  }

  logout() {
    this.authService.logout();
  }

}
