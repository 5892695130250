import * as _ from 'lodash';
import * as moment from 'moment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {Observable, Subject} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {ApiResponseModel} from './api-response.model';
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  constructor(private http: HttpClient) {
    // this.url = `http://localhost:8080`;
  }

  url = environment.backendUrl; // `http://localhost:8080`; // `https://crmt.ishayoga.live`;
  useLive = false;

  loadingChange = new Subject<boolean>();

  api(endpoint: string, values: Record<string, any> = {}) {
    const cmds = endpoint.split('.');
    const params: Record<string, string> = {action: cmds[1]};

    _.forEach(values, (v: string, k: string) => {
      if (v === '_today_') {
        params[k] = moment().format('YYYYMMDD');
      } else {
        params[k] = values[k];
      }
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Session ${this.getSessionKey()}`
      })
    };

    this.loadingChange.next(true);
    return this.http.post(`${this.url}/_api/${cmds[0]}`, new HttpParams({fromObject: params}), httpOptions)
      .pipe(finalize(() => {
        this.loadingChange.next(false);
        console.log(`${endpoint} end`);
      }));
  }

  get(endpoint: string, values = {}) {
    const qp = new HttpParams({fromObject: values});

    this.loadingChange.next(true);
    return this.http.get(`${this.url}/_api/${endpoint}${qp.toString()}`, this.jsonOpts())
      .pipe(finalize(() => {
        this.loadingChange.next(false);
        console.log(`${endpoint} end`);
      }));
  }

  post(endpoint: string, data?: object) {
    this.loadingChange.next(true);
    return this.http.post(`${this.url}/_api/${endpoint}`, data, this.jsonOpts())
      .pipe(finalize(() => {
        this.loadingChange.next(false);
        console.log(`${endpoint} end`);
      }));
  }

  jsonOpts() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Session ${this.getSessionKey()}`
      })
    };
  }

  gformSubmit(scriptId: string, params: any) {
    const formUrl = 'https://script.google.com/macros/s/' + scriptId + '/exec';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    console.log(params);

    this.loadingChange.next(true);
    return this.http.post(formUrl, new HttpParams({fromObject: params}), httpOptions)
      .pipe(finalize(() => {
        this.loadingChange.next(false);
      }));
  }

  setSessionKey(sessionKey: string): void {
    localStorage.setItem('SessionKey', sessionKey);
  }

  getSessionKey(): string | null {
    return localStorage.getItem('SessionKey');
  }

  prod():  boolean {
    return environment.production;
  }
}
