<div class="container" fxLayout fxLayoutGap="5px" fxLayoutAlign="center">
  <div fxFlex="30%" fxFlex.lt-md="5px"></div>
  <div fxFlex fxLayout="column">
    <div class="heading">
      <h2>Register as a Isha Portal Member</h2>
      <p>
        We were unable to find your profile in the system.
        <br>
        Please fill the below form to create your profile.
      </p>
    </div>
    <div fxFlex="grow" fxLayout="column">
      <div>
        <form fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap.gt-md="25px" #f="ngForm" (ngSubmit)="onSubmit(f)">
          <mat-form-field fxFlex.gt-md="calc(50% - 25px)" fxFlex="100%">
            <input type="text" matInput placeholder="First Name" ngModel name="firstName" required #firstName="ngModel">
            <mat-error *ngIf="firstName.hasError('required')">Required</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex.gt-md="calc(50% - 25px)" fxFlex="100%">
            <input type="text" matInput placeholder="Last Name" ngModel name="lastName" required #lastName="ngModel">
            <mat-error *ngIf="lastName.hasError('required')">Required</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="100%">
            <input type="email" matInput placeholder="Email Address" ngModel name="email" readonly #email="ngModel" [(ngModel)]="user!.email">
          </mat-form-field>
          <mat-form-field fxFlex.gt-md="calc(50% - 25px)" fxFlex="100%">
            <input type="text" matInput placeholder="Phone Number" ngModel name="phoneNumber" required #phoneNumber="ngModel">
            <mat-error *ngIf="phoneNumber.hasError('required')">Required</mat-error>
          </mat-form-field>
          <app-countries fxFlex.gt-md="calc(50% - 25px)" fxFlex="100%" name="country" ngModel required></app-countries>
          <div fxFlex fxLayoutAlign="center" [style.padding-top]="'16px'">
            <button mat-raised-button color="primary" type="submit" [disabled]="f.invalid??false" [ladda]="isLoading" fxFlex="initial">
              REGISTER
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="heading">
      <h2>&nbsp;</h2>
    </div>
  </div>
  <div fxFlex="30%" fxFlex.lt-md="5px"></div>
</div>
