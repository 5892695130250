<div fxFlex fxLayout="column" fxLayoutGap="10px">
  <div fxFlex fxLayoutAlign="center center">
    <div>
      For any questions about hosting sessions, please write to&nbsp;<a href="mailto:ishanga.apac@ishayoga.org">ishanga.apac@ishayoga.org</a>
    </div>
  </div>
  <div fxFlex fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="30px grid">
    <div>
      <div class="option-box" fxLayout="column" fxLayoutAlign="flex-start center" routerLink="session-kit">
        <span class="material-symbols-rounded action">cloud_download</span>
        <div>Download Session Materials</div>
      </div>
    </div>
    <div>
      <div class="option-box" fxLayout="column" fxLayoutAlign="flex-start center" routerLink="sessions"
           fxLayoutGap="10px">
        <span class="material-symbols-rounded action">home_work</span>
        <div>Publish Your Session</div>
      </div>
    </div>
    <div>
      <div class="option-box" fxLayout="column" fxLayoutAlign="flex-start center" routerLink="promote"
           fxLayoutGap="10px">
        <span class="material-symbols-rounded action">record_voice_over</span>
        <div>Promotional Materials</div>
      </div>
    </div>
    <div>
      <div class="option-box" fxLayout="column" fxLayoutAlign="flex-start center" routerLink="support-kit"
           fxLayoutGap="10px">
        <span class="material-symbols-rounded action">description</span>
        <div>Support Materials</div>
      </div>
    </div>
  </div>
</div>

