<div fxLayout="column">
  <mat-dialog-content>
    <h4 class="text-center">Download - {{data.file.title}} ({{data.item.lang}}) {{data.file.duration}}</h4>
    <h5 class="text-center">Right click on the link and Save Link as.</h5>
    <div *ngFor="let m of data.file.media" class="download-item">
      <a mat-raised-button [href]="m.link" [download]="'IUY_' + data.file.title + '_' + data.item.lang + '_' + data.file.duration + '_' + m.r" target="_blank">
        <mat-icon>cloud_download</mat-icon> Size: {{m.r}} - {{m.size}}
      </a>
    </div>
    <div *ngIf="data.file.link" class="download-item">
      <a mat-raised-button [href]="data.file.link" [download]="data.file.title" target="_blank">
        <mat-icon>cloud_download</mat-icon> Download
      </a>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutAlign="center">
    <button mat-raised-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
</div>

