import {Component, OnInit} from '@angular/core';
import {Resource, ResourceFile, RESOURCES} from "./resources";
import {MatDialog} from "@angular/material/dialog";
import {BackendService} from "../shared/backend.service";
import {Training} from "../shared/training.model";
import {Router} from "@angular/router";

@Component({
  selector: 'app-session-kit',
  templateUrl: './support-kit.component.html',
  styleUrls: ['./support-kit.component.scss']
})
export class SupportKitComponent implements OnInit {
  items = RESOURCES;

  constructor(public dialog: MatDialog, private backend: BackendService, private router: Router) {
  }

  ngOnInit() {
    this.query();
  }

  download(item: Resource, file: ResourceFile): void {
    window.open(
      file.link,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  query() {
    this.backend.get('trainings?completed=true&name=onlineyogaveeratraining', {})
      .subscribe((resp: any) => {
        if (!resp?.length) {
          this.router.navigate(['']).then();
        }
      });
  }
}
