import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {BackendService} from '../../shared/backend.service';

@Component({
  selector: 'app-login-success',
  templateUrl: './login-success.component.html',
  styleUrls: []
})
export class LoginSuccessComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router, private backend: BackendService) {
  }

  ngOnInit() {
    this.backend.setSessionKey(this.route.snapshot.params['key']);
    console.log(this.route.snapshot.params['key']);
    this.router.navigate(['']).then();
  }
}
