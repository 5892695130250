import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../auth.service';
import {NotificationService} from '../../shared/notification/notification.service';
import {Router} from '@angular/router';
import {faGoogle, faFacebookF, faMicrosoft, faYahoo} from '@fortawesome/free-brands-svg-icons';
import {BackendService} from '../../shared/backend.service';
import {PlatformLocation} from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loadingStatus = {
    google: false,
    facebook: false,
    yahoo: false,
    microsoft: false,
  };
  faGoogle = faGoogle;
  faFacebookF = faFacebookF;
  faMicrosoft = faMicrosoft;
  faYahoo = faYahoo;
  googleLoginLink?: string;
  facebookLoginLink?: string;
  microsoftLoginLink?: string;
  yahooLoginLink?: string;
  onSuccessUrl?: string;
  @ViewChild(ElementRef, {static: true}) oAuthForm?: ElementRef;

  constructor(private authService: AuthService, private platformLocation: PlatformLocation, private router: Router,
              private ntf: NotificationService, private backend: BackendService) {

  }

  ngOnInit() {
    this.onSuccessUrl = `${this.location()}/login`;
    this.googleLoginLink = `${this.backend.url}/auth/login/google`;
    this.facebookLoginLink = `${this.backend.url}/auth/login/facebook`;
    this.microsoftLoginLink = `${this.backend.url}/auth/login/microsoft`;
    this.yahooLoginLink = `${this.backend.url}/auth/login/yahoo`;
  }

  login(event: any, type: string) {
    // this.loadingStatus[type] = true;
    this.ntf.hide();
    this.ntf.msg('Logging in...');
    setTimeout(() => {
      event.target.submit();
    }, 0);
  }

  location(): string {
    let l = `${this.platformLocation.protocol}//${this.platformLocation.hostname}`;
    if (this.platformLocation.port) {
      l += `:${this.platformLocation.port}`
    }

    return l
  }
}
