<div class="container" fxLayout fxLayoutGap="5px" fxLayoutAlign="center">
  <div fxFlex="20%" fxFlex.xs="5px" fxFlex.md="5%"></div>
  <div fxFlex fxLayout="column">
    <div class="heading">
      <h2>Session Details</h2>
    </div>
    <form fxLayout="column" [formGroup]="form" (ngSubmit)="onSubmit()" fxLayoutGap="20px">
      <div fxLayout="column">
        <div fxLayout="row wrap" fxLayoutGap="20px grid">
          <mat-form-field fxFlex.lt-md="100%" fxFlex>
            <mat-label>Session Date Time</mat-label>
            <mat-datetimepicker-toggle [for]="datetimePicker" matSuffix></mat-datetimepicker-toggle>
            <mat-datetimepicker #datetimePicker type="datetime" [openOnFocus]="true" [timeInterval]="15"></mat-datetimepicker>
            <input matInput formControlName="sessionDateTime" name="sessionDateTime"
                   [matDatetimepicker]="datetimePicker" required autocomplete="false">
          </mat-form-field>
          <app-timezones formControlName="timezone" name="timezone" required fxFlex.lt-md="100%" fxFlex></app-timezones>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="20px grid">
          <mat-form-field fxFlex.lt-md="100%" fxFlex>
            <mat-label>Program Type</mat-label>
            <mat-select formControlName="programType" name="programType" required>
              <mat-option *ngFor="let d of programTypes" [value]="d" [disabled]="action=='UPDATE'">
                {{d}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex.lt-md="100%" fxFlex>
            <mat-label>Language</mat-label>
            <mat-select formControlName="language" name="language" required>
              <mat-option *ngFor="let l of languages" [value]="l">
                {{l}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div fxLayoutAlign="center center" fxFlex.lt-md="100%" fxFlex="shrink">
            <mat-slide-toggle labelPosition="before" formControlName="privateSession">Private Session</mat-slide-toggle>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="20px grid">
          <mat-form-field fxFlex>
            <mat-label>Venue Details</mat-label>
            <textarea matInput formControlName="venueDetails" name="venueDetails" rows="5" required></textarea>
          </mat-form-field>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="20px grid">
          <app-countries name="country" formControlName="country" required fxFlex.lt-md="100%" fxFlex></app-countries>
          <mat-form-field fxFlex>
            <mat-label>Postal Code</mat-label>
            <input matInput formControlName="postalCode" name="postalCode" required>
          </mat-form-field>
        </div>
      </div>

      <div fxFlex fxLayoutAlign="center" class="button-row" fxLayoutGap="20px">
        <button [disabled]="form.invalid" color="primary" fxFlex="initial" [ladda]="submitting"
                mat-raised-button type="submit">
          {{action}}
        </button>
      </div>
    </form>
  </div>
  <div fxFlex="20%" fxFlex.xs="5px" fxFlex.md="5%"></div>
</div>
