import * as _ from 'lodash';
import {Component, OnInit} from '@angular/core';
import {BackendService} from '../shared/backend.service';
import {ApiResponseModel} from '../shared/api-response.model';
import {AuthService} from '../auth/auth.service';
import {Router} from '@angular/router';
import {User} from '../auth/user.model';
import {NotificationService} from '../shared/notification/notification.service';

@Component({
  selector: 'app-reconcile',
  templateUrl: './reconcile.component.html',
  styleUrls: ['./reconcile.component.scss']
})
export class ReconcileComponent implements OnInit {
  user?: User | null;

  constructor(private backendService: BackendService, private authService: AuthService, private ntfService: NotificationService) {
  }

  ngOnInit() {
    this.user = this.authService.getUser();
  }

  reconcile(u: User) {
    this.backendService.api('portal.setVerified', {memberId: u.memberId})
      .subscribe((resp: any) => {
        this.ntfService.info('You have been logged out! Please login again to continue...');
        this.authService.logout();
      });
  }

}
