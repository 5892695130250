import {Component, OnInit, ViewChild} from '@angular/core';
import {BackendService} from '../shared/backend.service';

import * as moment from "moment";
import {MatTable} from "@angular/material/table";
import {ColDef, GridApi, GridReadyEvent} from "ag-grid-community";
import {AgGridAngular} from "ag-grid-angular";
import {BtnCellRendererComponent} from "../shared/grid/btn-cell-renderer/btn-cell-renderer.component";
import {Session} from "../shared/session.model";
import {Router} from "@angular/router";



@Component({
  selector: 'app-reconcile',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent implements OnInit {
  @ViewChild(MatTable) table?: MatTable<Session>;
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  gridApi!: GridApi<Session>;

  columnDefs: ColDef[] = [
    {
      minWidth: 75,
      cellRenderer: BtnCellRendererComponent,
      cellRendererParams: {
        buttonText: 'Edit',
        clicked: (ss:Session) => {
          this.router.navigate(['/session/edit', ss.id]).then();
        }
      }
    },
    {
      minWidth: 75,
      headerName: 'List',
      cellRenderer: BtnCellRendererComponent,
      cellRendererParams: {
        buttonText: 'List',
        clicked: (ss:Session) => {
          this.downloadList(ss);
        }
      }
    },
    {
      minWidth: 75,
      headerName: 'Link',
      cellRenderer: BtnCellRendererComponent,
      cellRendererParams: {
        buttonText: 'Link',
        clicked: (ss:Session) => {
          this.openRegLink(ss);
        }
      }
    },
    {field: 'id', headerName: 'ID'},
    {
      field: 'schedule',
      minWidth: 180,
      valueFormatter: (params: any) => {
        return params.value.format('DD-MMM-yy, h:mm a');
      }},
    {field: 'timeZone', minWidth: 180},
    {field: 'programType', minWidth: 180},
    {field: 'privateSession'},
    {field: 'language'},
    {field: 'venueDetails',
      valueFormatter: (params: any) => {
        return this.shortenVenue(params.value);
      }},
    {field: 'country'},
    {field: 'postalCode'}
  ];

  defaultColDef: ColDef = {
    sortable: true,
    resizable: true,
  };
  sessions: Session[] = [];

  constructor(private backend: BackendService, private router: Router) {
  }

  ngOnInit() {
  }

  createSession() {
    this.router.navigate(['/session/create']).then();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    params.columnApi.autoSizeAllColumns();
    this.query();
  }

  openRegLink(ss?: Session) {
    const pfx = this.backend.prod() ? '': 'qa.';
    const url = `https://${pfx}yogaveera.live`;
    window.open(`${url}/r/${ss?.id}`, '_blank')
  }

  programType(d: any) {
    if (d.programTypeId === 209) {
      return 'Yoga for Mental Health';
    }

    if (d.programTypeId === 108) {
      return 'Inner Engineering Intro with Isha Kriya';
    }

    return 'Yoga for Wellbeing';
  }

  query() {
    this.backend.get("programs-ext").subscribe((rsp: any) => {
      this.sessions = [];
      if (!rsp) return;

      rsp.forEach((d: any) => {
        this.sessions?.push({
          id: d.id,
          schedule: moment(d.sessionTimings[0].start * 1000),
          timeZone: d.timeZone,
          programType: this.programType(d),
          privateSession: d.privateSession || false,
          language: d.language,
          venueDetails: d.venueDetails,
          country: d.country,
          postalCode: d.postalCode,
          numRegistered: d.regSummary?.numRegistered,
        });
        this.gridApi.setRowData(this.sessions);
      });
    });
  }

  downloadList(ss: Session) {
    const headers = [
      'firstName',
      'lastName',
      'mobilePhone',
      'email',
      'postalCode',
      'country',
    ]
    this.backend.get(`registrations-ext/${ss.id}`).subscribe((rsp: any) => {
      const res = [];
      for (const r of rsp) {
        const v: Record<string, string> = {};
        for (const h of headers) {
          v[h] = r[h];
        }
        res.push(v);
      }

      this.exportCSVFile(headers, res, `reg-list-${ss.id}`);
    });
  }

  shortenVenue(vn: string): string {
    const pts = vn.split("\n");
    return this.truncate(pts[0])
  }

  truncate(s: string) {
    return s.length > 10 ? `${s.substring(0, 5)}...` : s;
  }

  convertToCSV(objArray: string) {
    const array = JSON.parse(objArray);
    let str = '';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index in array[i]) {
        if (line != '') line += ','

        line += array[i][index];
      }

      str += line + '\r\n';
    }

    return str;
  }

  exportCSVFile(headers: string[], items: any[], fileTitle: string) {
    if (headers) {
      items.unshift(headers);
    }

    const csv = this.convertToCSV(JSON.stringify(items));
    const exportedFilenmae = fileTitle + '.csv' || 'export.csv';
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    // @ts-ignore
    if (navigator.msSaveBlob) { // IE 10+
      // @ts-ignore
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

}
