<div fxLayout="column">
  <h3 mat-dialog-title class="text-center">Download Confirmation</h3>
  <mat-dialog-content>
    <ul>
      <li>
        I agree to offer the Isha Upa Yoga video sessions free of cost and will not use this
        class nor its resources for commercial purposes.
      </li>
      <li>
        I understand and agree that the Isha Upa Yoga sessions are available to all and will be offered
        to others without discrimination.
      </li>
      <li>I further understand and agree not to modify the Isha Upa Yoga session format or video content in any way.
      </li>
    </ul>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutAlign="center">
    <button mat-raised-button color="green" [mat-dialog-close]="true">I AGREE</button>
    <button mat-raised-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</div>
