<div class="container" fxLayout fxLayoutGap="5px" fxLayoutAlign="center">
  <div fxFlex="20%" fxFlex.xs="5px"></div>
  <div fxFlex fxLayout="column">
    <div class="heading">
      <h2>Multiple Records Found!</h2>
      <p>
        We have found multiple records under your email address "{{user?.email}}".
        <br>
        In order to continue using this portal, you will need to select one of them as your correct profile.
      </p>
      <p>
        Please choose one of the records from the list below:
      </p>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="10px grid">
      <div fxFlex="30" *ngFor="let u of user?.records" >
        <mat-card fxFlex="grow">
          <mat-card-header fxLayoutAlign="center">
            <mat-card-title>{{u.firstName}} {{u.lastName}}</mat-card-title>
          </mat-card-header>
          <mat-card-content fxLayoutAlign="center">
            <button mat-raised-button color="brown-orange" class="select" (click)="reconcile(u)">
              SELECT
            </button>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
  <div fxFlex="20%" fxFlex.xs="5px"></div>
</div>

